// import React, { useState, useEffect  } from 'react';
import React from 'react';
import ComingSoon from "react-coming-soon";
import './App.css';

function AddToSlackButton(props) {
  return (
    <a href={process.env.REACT_APP_ADD_TO_SLACK_LINK}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
  )
}

function App() {
  return (
    <ComingSoon
      image={process.env.PUBLIC_URL + '/world_map_PNG34.png'}
      bgColor="#555"
      textColor="#ff0"
      illustration="development"
      children=<AddToSlackButton />
    />
  );
}

/* function App() {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => fetch('/users').then(res => res.json()).then(users => setUsers(users));

  useEffect(() => {
    fetchUsers()
  }, []);

  return (
    <div className="App">
      <h1>Users</h1>
      {users.map(user =>
        <div key={user.id}>{user.username}</div>
      )}
    </div>
  );
}*/
export default App;
